import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import GlobalStyles from "../styled/globalStyles"
import GlobalFonts from "../styled/globalFonts"

import SEO from "./seo"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title={data.site.siteMetadata.title} />
      <GlobalStyles />
      <GlobalFonts />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
