import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { Colors, Sizes } from "../../components/theme"
import { Container } from "../styled/container"

import logo from "../../../static/images/footer-logo.png"

const FooterContainer = styled.footer`
  background: ${Colors.scdsDark};
  width: 100%;
  padding: 40px 0;
  margin-top: 40px;
`

const FooterItemsContainer = styled.footer`
  display: flex;
  color: white;
  div {
    flex-grow: 1;
    flex-basis: 0;
  }

  @media (max-width: ${Sizes.mobile}) {
    flex-direction: column;
  }
`
const Footer = () => {
  const intl = useIntl()

  return (
    <FooterContainer>
      <Container>
        <FooterItemsContainer>
          <div>
            <img src={logo} alt="Logotipo" />
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "footer_info" })}</h4>
            <p>{intl.formatMessage({ id: "footer_contact" })}</p>
            <p>{intl.formatMessage({ id: "footer_sitemap" })}</p>
          </div>
          <div>
            <h4>{intl.formatMessage({ id: "footer_email" })}</h4>
            <p>{intl.formatMessage({ id: "footer_text" })}</p>
          </div>
          <hr />
        </FooterItemsContainer>
      </Container>
    </FooterContainer>
  )
}

export default Footer
