import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`

  @font-face {
    font-family: "Inter";
    font-weight: 900;
    font-style: bold;
    src: url("../../../fonts/Inter/Inter-Black.otf");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 600;
    font-style: normal;
    src: url("../../../fonts/Inter/Inter-Bold.otf");
  }

  @font-face {
    font-family: "Inter";
    font-weight: 400;
    font-style: normal;
    src: url("../../../fonts/Inter/Inter-Regular.otf");
  }

  @font-face {
    font-family: "Merriweather";
    font-weight: normal;
    font-style: normal;
    src: url("../../../fonts/Merriweather/Merriweather-Regular.ttf");
  }

  body {
    font-family: 'Inter';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "Inter";
      font-weight: normal;
  }

  `
