import { useStaticQuery, graphql } from "gatsby"

export const useSearchData = () => {
  const searchData = useStaticQuery(
    graphql`
      {
        localSearchPages {
          store
          index
        }
      }
    `
  )
  return searchData
}
