import styled from "styled-components"
import { Sizes } from "../theme"

const mainWidth = `790px`

export const Container = styled.div`
  width: ${mainWidth};
  margin: auto;

  @media (max-width: ${Sizes.mobile}) {
    width: 90%;
    padding: 0;
  }
`
