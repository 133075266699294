import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { Container } from "../styled/container"
import { Colors, Sizes } from "../../components/theme"
import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from "formik"
import { useSearchData } from "../../hooks/useSearchData"

import logo from "../../../static/images/logo.png"
import flag_es from "../../../static/images/flag-spain.png"
import flag_en from "../../../static/images/flag-uk.png"
import linkIcon from "../../../static/images/icon-link.png"

const TopHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;

  @media (max-width: ${Sizes.mobile}) {
    display: none;
    margin-top: 10px;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  max-width: 70px;
`

const Info = styled.div`
  display: flex;

  a {
    margin-right: 20px;
  }
  @media (max-width: ${Sizes.mobile}) {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-top: 1px dashed #f0f0f0;
    padding-top: 20px;
  }
`

const Language = styled.div`
  display: flex;
  a {
    color: ${Colors.scdsDark};
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
  }

  img {
    margin-right: 10px;
    max-width: 30px;
  }
`

const LanguageMobileContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

const Navigation = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: ${Colors.scdsDark};
    padding: 20px 0;
    margin-right: 25px;
    text-decoration: none;
  }

  @media (max-width: ${Sizes.mobile}) {
    display: none;
    flex-direction: column;
    font-size: 20px;
    align-items: baseline;
    line-height: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const SearchForm = styled.div`
  input {
    border: 2px solid black;
    padding: 5px;
  }

  @media (max-width: ${Sizes.mobile}) {
    margin-bottom: 30px;
    width: 90%;
  }
`

const SearchResults = styled.div`
  border-top: ${props =>
    props.results.length === 0 ? `none` : `2px solid  ${Colors.scdsDark}`};
  border-bottom: ${props =>
    props.results.length === 0 ? `none` : `2px solid  ${Colors.scdsDark}`};
  margin-bottom: 35px;
  li {
    list-style-type: none;
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    color: ${Colors.scdsDark};
    font-size: 17px;
    font-weight: 400;
  }
  a {
    text-decoration: none;
  }
  img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }
`

const MenuButton = styled.div`
  display: none;
  button {
    background: white;
    border: 2px solid #008285;
    font-weight: bold;
  }

  @media (max-width: ${Sizes.mobile}) {
    display: flex;
    justify-content: space-between;
  }
`

const MobileHeader = styled.div`
  margin-top: 25px;
`
const Header = () => {
  const searchData = useSearchData()
  const index = searchData.localSearchPages.index
  const store = searchData.localSearchPages.store

  const intl = useIntl()
  const locale = intl.locale !== "es" ? `${intl.locale}` : "es"

  const [query, setQuery] = useState("")
  const [isMenuOpen, setMenu] = useState(false)
  const results = useFlexSearch(query, index, JSON.parse(store))

  const handleMenu = e => {
    isMenuOpen ? setMenu(false) : setMenu(true)
  }

  return (
    <header>
      <Container>
        <TopHeader>
          <Link to={`/${locale}/`}>
            <Logo>
              <img alt="Logotipo" src={logo} />
            </Logo>
          </Link>
          <Info>
            <Language>
              <Link to={`/en`}>
                <img alt="English Language" src={flag_en} />{" "}
                <span style={locale === "en" ? { fontWeight: 800 } : {}}>
                  {" "}
                  {intl.formatMessage({ id: "language_english" })}
                </span>
              </Link>
            </Language>
            <Language>
              <Link to={`/es`}>
                <img alt="Idioma Español" src={flag_es} />{" "}
                <span style={locale === "es" ? { fontWeight: 800 } : {}}>
                  {" "}
                  {intl.formatMessage({ id: "language_spanish" })}
                </span>
              </Link>
            </Language>
            <SearchForm>
              <Formik
                initialValues={{ query: "" }}
                onSubmit={(values, { setSubmitting }) => {
                  setQuery(values.query)
                  setSubmitting(false)
                }}
              >
                <Form>
                  <Field name="query" />
                </Form>
              </Formik>
            </SearchForm>
          </Info>
        </TopHeader>
        <Navigation>
          <Link activeStyle={{ fontWeight: "bold" }} to={`/${locale}/`}>
            {intl.formatMessage({ id: "header_home" })}
          </Link>
          <Link
            activeStyle={{ fontWeight: "bold" }}
            to={`/${locale}/servicios`}
          >
            {intl.formatMessage({ id: "header_services" })}
          </Link>
          <Link
            activeStyle={{ fontWeight: "bold" }}
            to={`/${locale}/publicaciones`}
          >
            {intl.formatMessage({ id: "header_posts" })}
          </Link>
          <Link
            activeStyle={{ fontWeight: "bold" }}
            to={`/${locale}/sobre-nosotros`}
          >
            {intl.formatMessage({ id: "header_about" })}
          </Link>
        </Navigation>
        <MobileHeader>
          <MenuButton style={{ fontSize: 25 }}>
            {" "}
            <Link to="/">
              <Logo>
                <img alt="Logotipo" src={logo} />
              </Logo>
            </Link>
            <button onClick={handleMenu}>≡</button>
          </MenuButton>
        </MobileHeader>

        {isMenuOpen ? (
          <>
            {" "}
            <Navigation
              style={isMenuOpen ? { display: "flex" } : { display: "none" }}
            >
              <Link activeStyle={{ fontWeight: "bold" }} to="/">
                Inicio
              </Link>
              <Link activeStyle={{ fontWeight: "bold" }} to="/servicios">
                Servicios
              </Link>
              <Link activeStyle={{ fontWeight: "bold" }} to="/publicaciones">
                Publicaciones
              </Link>
              <Link activeStyle={{ fontWeight: "bold" }} to="/sobre-nosotros">
                Sobre nosotros
              </Link>
            </Navigation>
            <Info>
              <LanguageMobileContainer>
                <Language>
                  <a href="/">
                    <img alt="English Language" src={flag_en} />{" "}
                    <span>
                      {intl.formatMessage({ id: "language_english" })}
                    </span>
                  </a>
                </Language>
                <Language>
                  <a href="/">
                    <img alt="Idioma Español" src={flag_es} />{" "}
                    <span>
                      {intl.formatMessage({ id: "language_spanish" })}
                    </span>
                  </a>
                </Language>
              </LanguageMobileContainer>
              <SearchForm>
                <Formik
                  initialValues={{ query: "" }}
                  onSubmit={(values, { setSubmitting }) => {
                    setQuery(values.query)
                    setSubmitting(false)
                  }}
                >
                  <Form>
                    Buscar
                    <Field name="query" />
                  </Form>
                </Formik>
              </SearchForm>
            </Info>
          </>
        ) : (
          <p></p>
        )}
      </Container>
      <Container>
        <SearchResults results={results}>
          {results.map(result => {
            console.log(result)
            return (
              <Link key={result.id} to={`/${result.language}/${result.slug}`}>
                <li>
                  <img src={linkIcon} alt="Icono Enlace" />
                  <img
                    alt="Idioma"
                    src={result.language === "es" ? flag_es : flag_en}
                  />{" "}
                  {result.title}
                </li>
              </Link>
            )
          })}
        </SearchResults>
      </Container>
    </header>
  )
}

export default Header
