export const Colors = {
  scdsMain: "#008285",
  scdsDark: "#1A192F",
  scdsLight: "#23B3A1",
  dark: "#1A192F",
  light: "#FAFAFD",
}

export const Sizes = {
  mobile: "820px",
}
